import React, { useState } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Text,
    Button,
    Box,
    Flex,
    useToast,
    NumberInput,
    NumberInputField,
    VStack,
    HStack,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Heading,
} from '@chakra-ui/react';
import { MdOutlineChevronRight } from 'react-icons/md';

const PredSnapBsb = () => {
    const [inputs, setInputs] = useState({
        ey: '',
        er: '',
        fy: '',
        fr: '',
        kb: '',
        kg: '',
        cb: '',
        cg: '',
        tb: '',
        tg: '',
        e_methyl: '',
        f_methyl: '',
        k_methyl: '',
        c_methyl: '',
        t_methyl: '',
        result3130b_methyl: '',
        result3130s_methyl: '',
        result3130bu_methyl: '',
        result3130a_methyl: '',
        result3500b_methyl: '',
        result3500s_methyl: '',
        result3500a_methyl: '',
        result3130Seqb_methyl: '',
        result3130Seqs_methyl: '',
        result3130Seqa_methyl: '',
    });

    const [results, setResults] = useState({
        eResult: null,
        fResult: null,
        kResult: null,
        cResult: null,
        tResult: null,
        result3130b: null,
        result3130s: null,
        result3130bu: null,
        result3130a: null,
        result3500b: null,
        result3500s: null,
        result3500a: null,
        result3130Seqb: null,
        result3130Seqs: null,
        result3130Seqa: null,
        result3130b_methyl: null,
        result3130s_methyl: null,
        result3130bu_methyl: null,
        result3130a_methyl: null,
        result3500b_methyl: null,
        result3500s_methyl: null,
        result3500a_methyl: null,
        result3130Seqb_methyl: null,
        result3130Seqs_methyl: null,
        result3130Seqa_methyl: null,
    });

    const [showResultsA, setShowResultsA] = useState(false);
    const [showResultsB, setShowResultsB] = useState(false);
    const [isCalculateBDisabled, setIsCalculateBDisabled] = useState(false);
    const [warningMessage1, setWarningMessage1] = useState(false);
    const [warningMessage2, setWarningMessage2] = useState(false);

    const toast = useToast();

    const handlePaste = (e) => {
        e.preventDefault();
        const paste = e.clipboardData
            .getData('text')
            .split(/\r\n|\n|\r/)
            .map((row) => row.split('\t').map((cell) => cell.trim()));
        const fields = [
            'ey',
            'er',
            'fy',
            'fr',
            'kb',
            'kg',
            'cb',
            'cg',
            'tb',
            'tg',
        ];
        let updatedInputs = { ...inputs };
        paste.forEach((value, index) => {
            if (fields[index]) {
                updatedInputs[fields[index]] = value;
            }
        });
        setInputs(updatedInputs);
    };

    const handlePasteMethyl = (e) => {
        e.preventDefault();
        const paste = e.clipboardData
            .getData('text')
            .split(/\r\n|\n|\r/)
            .map((row) => row.split('\t').map((cell) => cell.trim()));
        const fields = [
            'e_methyl',
            'f_methyl',
            'k_methyl',
            'c_methyl',
            't_methyl',
        ];
        let updatedInputs = { ...inputs };
        paste.forEach((value, index) => {
            if (fields[index]) {
                updatedInputs[fields[index]] = value;
            }
        });
        setInputs(updatedInputs);
    };

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setInputs((prev) => ({ ...prev, [id]: value }));
    };

    const handleReset = () => {
        setInputs({});
        setResults({});
        setShowResultsA(false);
        setShowResultsA(false);
    };

    const tablerow = [
        {
            name: 'ELOV2',
            first: 'Yellow',
            second: 'Red',
            var1: 'ey',
            var2: 'er',
            result: results.eResult,
            input: 'e_methyl',
        },
        {
            name: 'FHL2',
            first: 'Yellow',
            second: 'Red',
            var1: 'fy',
            var2: 'fr',
            result: results.fResult,
            input: 'f_methyl',
        },
        {
            name: 'KLF14',
            first: 'Blue',
            second: 'Green',
            var1: 'kb',
            var2: 'kg',
            result: results.kResult,
            input: 'k_methyl',
        },
        {
            name: 'C1orf132',
            first: 'Blue',
            second: 'Green',
            var1: 'cb',
            var2: 'cg',
            result: results.cResult,
            input: 'c_methyl',
        },
        {
            name: 'TRIM59',
            first: 'Blue',
            second: 'Green',
            var1: 'tb',
            var2: 'tg',
            result: results.tResult,
            input: 't_methyl',
        },
    ];

    const handleCalculate = () => {
        const { ey, er, fy, fr, kb, kg, cb, cg, tb, tg } = inputs;

        const eResult = parseFloat(ey) / (parseFloat(ey) + parseFloat(er));
        const fResult = parseFloat(fy) / (parseFloat(fy) + parseFloat(fr));
        const kResult = parseFloat(kb) / (parseFloat(kb) + parseFloat(kg));
        const cResult = parseFloat(cb) / (parseFloat(cb) + parseFloat(cg));
        const tResult = parseFloat(tb) / (parseFloat(tb) + parseFloat(tg));

        const result3130b =
            8.052 +
            55.673 * eResult +
            47.141 * fResult +
            62.87 * kResult +
            -29.075 * cResult +
            23.671 * tResult;
        const result3130s =
            7.527 +
            47.078 * eResult +
            42.389 * fResult +
            48.479 * kResult +
            -28.159 * cResult +
            41.281 * tResult;
        const result3130bu =
            -9.277 +
            32.589 * eResult +
            49.115 * fResult +
            41.814 * kResult +
            -9.228 * cResult +
            59.484 * tResult;
        const result3130a =
            -3.447 +
            44.59 * eResult +
            50.188 * fResult +
            49.747 * kResult +
            -17.131 * cResult +
            43.22 * tResult;
        const result3500b =
            8.052 +
            55.673 * (1.05 * eResult + 0.07) +
            47.141 * (1.07 * fResult + 0.06) +
            62.87 * (1.53 * kResult + 0.00464) +
            -29.075 * (0.81 * cResult + 0.23) +
            23.671 * (0.9 * tResult + 0.05);
        const result3500s =
            7.527 +
            47.078 * (1.05 * eResult + 0.07) +
            42.389 * (1.07 * fResult + 0.06) +
            48.479 * (1.53 * kResult + 0.00464) +
            -28.159 * (0.81 * cResult + 0.23) +
            41.281 * (0.9 * tResult + 0.05);
        const result3500a =
            -3.447 +
            44.59 * (1.05 * eResult + 0.07) +
            50.188 * (1.07 * fResult + 0.06) +
            49.747 * (1.53 * kResult + 0.00464) +
            -17.131 * (0.81 * cResult + 0.23) +
            43.22 * (0.9 * tResult + 0.05);
        const result3130Seqb =
            8.052 +
            55.673 * (1.13 * eResult + 0.11) +
            47.141 * (1.24 * fResult + 0.08) +
            62.87 * (1.69 * kResult + 0.00546) +
            -29.075 * (0.75 * cResult + 0.29) +
            23.671 * (1.1 * tResult + 0.06);
        const result3130Seqs =
            7.527 +
            47.078 * (1.13 * eResult + 0.11) +
            42.389 * (1.24 * fResult + 0.08) +
            48.479 * (1.69 * kResult + 0.00546) +
            -28.159 * (0.75 * cResult + 0.29) +
            41.281 * (1.1 * tResult + 0.06);
        const result3130Seqa =
            -3.447 +
            44.59 * (1.13 * eResult + 0.11) +
            50.188 * (1.24 * fResult + 0.08) +
            49.747 * (1.69 * kResult + 0.00546) +
            -17.131 * (0.75 * cResult + 0.29) +
            43.22 * (1.1 * tResult + 0.06);

        const resultA = {
            eResult: 'Value e',
            fResult: 'Value f',
            kResult: 'Value k',
            cResult: 'Value c',
            tResult: 'Value t',

            result3130b: 'Value A1',
            result3130s: 'Value A2',
            result3130bu: 'Value A3',
            result3130a: 'Value A4',
            result3500b: 'Value A5',
            result3500s: 'Value A6',
            result3500a: 'Value A7',
            result3130Seqb: 'Value A8',
            result3130Seqs: 'Value A9',
            result3130Seqa: 'Value A10',
        };

        setResults({
            ...resultA,
            eResult: eResult.toFixed(4),
            fResult: fResult.toFixed(4),
            kResult: kResult.toFixed(4),
            cResult: cResult.toFixed(4),
            tResult: tResult.toFixed(4),
            result3130b: result3130b.toFixed(4),
            result3130s: result3130s.toFixed(4),
            result3130bu: result3130bu.toFixed(4),
            result3130a: result3130a.toFixed(4),
            result3500b: result3500b.toFixed(4),
            result3500s: result3500s.toFixed(4),
            result3500a: result3500a.toFixed(4),
            result3130Seqb: result3130Seqb.toFixed(4),
            result3130Seqs: result3130Seqs.toFixed(4),
            result3130Seqa: result3130Seqa.toFixed(4),
        });

        setShowResultsA(true);
        setShowResultsB(false);
        setIsCalculateBDisabled(true);
    };

    const handleCalculateMethyl = () => {
        const { e_methyl, f_methyl, k_methyl, c_methyl, t_methyl } = inputs;

        const isEmpty = [e_methyl, f_methyl, k_methyl, c_methyl, t_methyl].some(
            (val) => val === ''
        );

        const resultB = {
            result3130b_methyl: 'Value B1',
            result3130s_methyl: 'Value B2',
            result3130bu_methyl: 'Value B3',
            result3130a_methyl: 'Value B4',
            result3500b_methyl: 'Value B5',
            result3500s_methyl: 'Value B6',
            result3500a_methyl: 'Value B7',
            result3130Seqb_methyl: 'Value B8',
            result3130Seqs_methyl: 'Value B9',
            result3130Seqa_methyl: 'Value B10',
        };

        if (isEmpty) {
            setWarningMessage2(true);
            setResults({
                ...resultB,
                result3130b_methyl: NaN,
                result3130s_methyl: NaN,
                result3130bu_methyl: NaN,
                result3130a_methyl: NaN,
                result3500b_methyl: NaN,
                result3500s_methyl: NaN,
                result3500a_methyl: NaN,
                result3130Seqb_methyl: NaN,
                result3130Seqs_methyl: NaN,
                result3130Seqa_methyl: NaN,
            });
        } else {
            setWarningMessage2(false);

            const result3130b_methyl =
                8.052 +
                55.673 * parseFloat(e_methyl) +
                47.141 * parseFloat(f_methyl) +
                62.87 * parseFloat(k_methyl) +
                -29.075 * parseFloat(c_methyl) +
                23.671 * parseFloat(t_methyl);
            const result3130s_methyl =
                7.527 +
                47.078 * parseFloat(e_methyl) +
                42.389 * parseFloat(f_methyl) +
                48.479 * parseFloat(k_methyl) +
                -28.159 * parseFloat(c_methyl) +
                41.281 * parseFloat(t_methyl);
            const result3130bu_methyl =
                -9.277 +
                32.589 * parseFloat(e_methyl) +
                49.115 * parseFloat(f_methyl) +
                41.814 * parseFloat(k_methyl) +
                -9.228 * parseFloat(c_methyl) +
                59.484 * parseFloat(t_methyl);
            const result3130a_methyl =
                -3.447 +
                44.59 * parseFloat(e_methyl) +
                50.188 * parseFloat(f_methyl) +
                49.747 * parseFloat(k_methyl) +
                -17.131 * parseFloat(c_methyl) +
                43.22 * parseFloat(t_methyl);
            const result3500b_methyl =
                8.052 +
                55.673 * (1.05 * parseFloat(e_methyl) + 0.07) +
                47.141 * (1.07 * parseFloat(f_methyl) + 0.06) +
                62.87 * (1.53 * parseFloat(k_methyl) + 0.00464) +
                -29.075 * (0.81 * parseFloat(c_methyl) + 0.23) +
                23.671 * (0.9 * parseFloat(t_methyl) + 0.05);
            const result3500s_methyl =
                7.527 +
                47.078 * (1.05 * parseFloat(e_methyl) + 0.07) +
                42.389 * (1.07 * parseFloat(f_methyl) + 0.06) +
                48.479 * (1.53 * parseFloat(k_methyl) + 0.00464) +
                -28.159 * (0.81 * parseFloat(c_methyl) + 0.23) +
                41.281 * (0.9 * parseFloat(t_methyl) + 0.05);
            const result3500a_methyl =
                -3.447 +
                44.59 * (1.05 * parseFloat(e_methyl) + 0.07) +
                50.188 * (1.07 * parseFloat(f_methyl) + 0.06) +
                49.747 * (1.53 * parseFloat(k_methyl) + 0.00464) +
                -17.131 * (0.81 * parseFloat(c_methyl) + 0.23) +
                43.22 * (0.9 * parseFloat(t_methyl) + 0.05);
            const result3130Seqb_methyl =
                8.052 +
                55.673 * (1.13 * parseFloat(e_methyl) + 0.11) +
                47.141 * (1.24 * parseFloat(f_methyl) + 0.08) +
                62.87 * (1.69 * parseFloat(k_methyl) + 0.00546) +
                -29.075 * (0.75 * parseFloat(c_methyl) + 0.29) +
                23.671 * (1.1 * parseFloat(t_methyl) + 0.06);
            const result3130Seqs_methyl =
                7.527 +
                47.078 * (1.13 * parseFloat(e_methyl) + 0.11) +
                42.389 * (1.24 * parseFloat(f_methyl) + 0.08) +
                48.479 * (1.69 * parseFloat(k_methyl) + 0.00546) +
                -28.159 * (0.75 * parseFloat(c_methyl) + 0.29) +
                41.281 * (1.1 * parseFloat(t_methyl) + 0.06);
            const result3130Seqa_methyl =
                -3.447 +
                44.59 * (1.13 * parseFloat(e_methyl) + 0.11) +
                50.188 * (1.24 * parseFloat(f_methyl) + 0.08) +
                49.747 * (1.69 * parseFloat(k_methyl) + 0.00546) +
                -17.131 * (0.75 * parseFloat(c_methyl) + 0.29) +
                43.22 * (1.1 * parseFloat(t_methyl) + 0.06);

            setResults({
                ...results,
                result3130b_methyl: result3130b_methyl.toFixed(4),
                result3130s_methyl: result3130s_methyl.toFixed(4),
                result3130bu_methyl: result3130bu_methyl.toFixed(4),
                result3130a_methyl: result3130a_methyl.toFixed(4),
                result3500b_methyl: result3500b_methyl.toFixed(4),
                result3500s_methyl: result3500s_methyl.toFixed(4),
                result3500a_methyl: result3500a_methyl.toFixed(4),
                result3130Seqb_methyl: result3130Seqb_methyl.toFixed(4),
                result3130Seqs_methyl: result3130Seqs_methyl.toFixed(4),
                result3130Seqa_methyl: result3130Seqa_methyl.toFixed(4),
            });
        }

        setShowResultsA(false);
        setShowResultsB(true);
    };

    return (
        <Box p={4}>
            {/* Header */}
            <VStack
                spacing="12px"
                align="left"
                justifyContent="space-between"
                mb={6}
            >
                <VStack
                    spacing="7px"
                    align="left"
                    justifyContent="space-between"
                    mb={4}
                >
                    <Breadcrumb
                        fontSize="s"
                        spacing="6px"
                        separator={<MdOutlineChevronRight color="gray.500" />}
                    >
                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink>SNapShot</BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink>
                                Blood, Saliva, Buccal Swab
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                    <Heading as="h1" size="lg">
                        Blood, Saliva, and Buccal Swab
                    </Heading>
                </VStack>
                <HStack>
                    <Button
                        as="a"
                        variant="link"
                        href="../documents/3_Age-prediction-using-DNA-methylation-for-blood-saliva-buccal-cells.pdf"
                        download
                        color="#2b6cb0"
                        mb={4}
                    >
                        Download Protocol (PDF)
                    </Button>
                </HStack>
            </VStack>
            {/* Calculator */}
            <VStack
                spacing="12px"
                align="left"
                justifyContent="space-between"
                mb={6}
            >
                {/* Inputs & Methyl Results */}
                <Flex
                    // backgroundColor="gray.20"
                    direction="row"
                    height="750px"
                    width="800px"
                >
                    <Box flex="6.5" onPaste={handlePaste}>
                        {/* Table Left */}
                        <Table colorScheme="blue" height="100%" size="sm">
                            <Thead>
                                <Tr borders="0">
                                    <Th>
                                        <Button
                                            colorScheme="gray"
                                            onClick={handleReset}
                                            m={2}
                                        >
                                            Reset All
                                        </Button>
                                    </Th>
                                    <Th></Th>
                                    <Th>
                                        <Box align="center">
                                            <Button
                                                colorScheme="blue"
                                                onClick={handleCalculate}
                                                m={2}
                                                w="70%"
                                            >
                                                Calculate
                                            </Button>
                                        </Box>
                                    </Th>
                                </Tr>
                                <Tr bg="gray.50">
                                    <Td></Td>
                                    <Td></Td>
                                    <Td textAlign="center">
                                        <Text fontSize="md">Input (rfu)</Text>
                                    </Td>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {tablerow.map((item) => (
                                    <React.Fragment key={item.name}>
                                        <Tr>
                                            <Td rowSpan="2">
                                                <Text
                                                    fontSize="md"
                                                    textAlign="center"
                                                >
                                                    {item.name}
                                                </Text>
                                            </Td>
                                            <Td>
                                                <Text
                                                    fontSize="md"
                                                    textAlign="center"
                                                >
                                                    {item.first}
                                                </Text>
                                            </Td>
                                            <Td>
                                                <NumberInput
                                                    // size="sm"
                                                    id={item.var1}
                                                    value={
                                                        inputs[item.var1] || ''
                                                    }
                                                    min={0}
                                                    onChange={(valueString) =>
                                                        handleInputChange({
                                                            target: {
                                                                id: item.var1,
                                                                value: valueString,
                                                            },
                                                        })
                                                    }
                                                    keepWithinRange={true}
                                                    m={0} // Remove all margins
                                                    mt="2px" // Top margin (adjust as needed)
                                                    mb="2px"
                                                >
                                                    <NumberInputField />
                                                </NumberInput>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td>
                                                <Text
                                                    fontSize="md"
                                                    textAlign="center"
                                                >
                                                    {item.second}
                                                </Text>
                                            </Td>
                                            <Td>
                                                <NumberInput
                                                    // size="sm"
                                                    id={item.var2}
                                                    value={
                                                        inputs[item.var2] || ''
                                                    }
                                                    min={0}
                                                    onChange={(valueString) =>
                                                        handleInputChange({
                                                            target: {
                                                                id: item.var2,
                                                                value: valueString,
                                                            },
                                                        })
                                                    }
                                                    keepWithinRange={true}
                                                    m={0} // Remove all margins
                                                    mt="2px" // Top margin (adjust as needed)
                                                    mb="2px"
                                                >
                                                    <NumberInputField />
                                                </NumberInput>
                                            </Td>
                                        </Tr>
                                    </React.Fragment>
                                ))}
                            </Tbody>
                        </Table>
                    </Box>
                    <Box flex="3.5" onPaste={handlePasteMethyl}>
                        {/* Table Right */}
                        <Table colorScheme="blue" height="100%" size="sm">
                            <Thead>
                                <Tr>
                                    <Th alignItems="center">
                                        <Box align="center">
                                            <Button
                                                colorScheme="blue"
                                                onClick={handleCalculateMethyl}
                                                disabled={isCalculateBDisabled}
                                                m={2}
                                                width="70%"
                                            >
                                                Calculate
                                            </Button>
                                        </Box>
                                    </Th>
                                </Tr>
                                <Tr bg="gray.50">
                                    <Td textAlign="center">
                                        <Text fontSize="md">
                                            Methylation (0-1)
                                        </Text>
                                    </Td>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {tablerow.map((item) => (
                                    <React.Fragment key={item.name}>
                                        <Tr>
                                            <Td rowSpan={2}>
                                                {showResultsA ? (
                                                    <Text
                                                        fontSize="md"
                                                        align="center"
                                                    >
                                                        {item.result}
                                                    </Text>
                                                ) : (
                                                    <NumberInput
                                                        width="100%"
                                                        id={item.input}
                                                        min={0}
                                                        max={1}
                                                        value={
                                                            inputs[
                                                                item.input
                                                            ] || ''
                                                        }
                                                        onChange={(
                                                            valueString
                                                        ) =>
                                                            handleInputChange({
                                                                target: {
                                                                    id: item.input,
                                                                    value: valueString,
                                                                },
                                                            })
                                                        }
                                                        m={0} // Remove all margins
                                                        mt={1} // Top margin (adjust as needed)
                                                        mb={1}
                                                    >
                                                        <NumberInputField />
                                                    </NumberInput>
                                                )}
                                            </Td>
                                        </Tr>
                                        <Tr></Tr>
                                    </React.Fragment>
                                ))}
                            </Tbody>
                        </Table>
                    </Box>
                </Flex>
                {/* Age Est. Results */}
                <Flex
                    direction="row"
                    // height="auto"
                    width="800px"
                    bg="gray.50"
                >
                    <Box flex="6.5">
                        {/* Table left */}
                        <Table colorScheme="blue" height="100%">
                            <Tbody>
                                <Tr>
                                    <Td colSpan="2" rowSpan="4">
                                        <Text whiteSpace="pre-line">
                                            Estimated age (years) for 3130
                                        </Text>
                                    </Td>
                                    <Td>Blood</Td>
                                </Tr>
                                <Tr>
                                    <Td>Saliva</Td>
                                </Tr>
                                <Tr>
                                    <Td>Buccal Swab</Td>
                                </Tr>
                                <Tr>
                                    <Td>All</Td>
                                </Tr>
                                <Tr>
                                    <Td colSpan="2" rowSpan="3">
                                        <Text whiteSpace="pre-line">
                                            So et al. for 3500
                                        </Text>
                                    </Td>
                                    <Td>Blood</Td>
                                </Tr>
                                <Tr>
                                    <Td>Saliva</Td>
                                </Tr>
                                <Tr>
                                    <Td>All</Td>
                                </Tr>
                                <Tr>
                                    <Td colSpan="2" rowSpan="3">
                                        <Text whiteSpace="pre-line">
                                            So et al. for SeqStudio
                                        </Text>
                                    </Td>
                                    <Td>Blood</Td>
                                </Tr>
                                <Tr>
                                    <Td>Saliva</Td>
                                </Tr>
                                <Tr>
                                    <Td>All</Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </Box>
                    <Box flex="3.5">
                        {/* Table right */}
                        <Table colorScheme="blue" height="100%">
                            <Tbody>
                                <Tr>
                                    <Td>
                                        {showResultsA
                                            ? results.result3130b
                                            : showResultsB
                                            ? results.result3130b_methyl
                                            : ''}
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>
                                        {showResultsA
                                            ? results.result3130s
                                            : showResultsB
                                            ? results.result3130s_methyl
                                            : ''}
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>
                                        {showResultsA
                                            ? results.result3130bu
                                            : showResultsB
                                            ? results.result3130bu_methyl
                                            : ''}
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>
                                        {showResultsA
                                            ? results.result3130a
                                            : showResultsB
                                            ? results.result3130a_methyl
                                            : ''}
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>
                                        {showResultsA
                                            ? results.result3500b
                                            : showResultsB
                                            ? results.result3500b_methyl
                                            : ''}
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>
                                        {showResultsA
                                            ? results.result3500s
                                            : showResultsB
                                            ? results.result3500s_methyl
                                            : ''}
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>
                                        {showResultsA
                                            ? results.result3500a
                                            : showResultsB
                                            ? results.result3500a_methyl
                                            : ''}
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>
                                        {showResultsA
                                            ? results.result3130Seqb
                                            : showResultsB
                                            ? results.result3130Seqb_methyl
                                            : ''}
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>
                                        {showResultsA
                                            ? results.result3130Seqs
                                            : showResultsB
                                            ? results.result3130Seqs_methyl
                                            : ''}
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>
                                        {showResultsA
                                            ? results.result3130Seqa
                                            : showResultsB
                                            ? results.result3130Seqa_methyl
                                            : ''}
                                    </Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </Box>
                </Flex>
            </VStack>
            {/* CpG Info */}
            <Flex
                // backgroundColor="gray.20"
                direction="row"
                width="800px"
            >
                <VStack
                    spacing="12px"
                    align="left"
                    justifyContent="space-between"
                    // mb={6}
                    mt={6}
                    pt={6}
                >
                    <Box>
                        <Text fontSize="xl" fontWeight="bold" color="#2b6cb0">
                            CpG Marker Information
                        </Text>
                    </Box>
                    <Box>
                        <Table colorScheme="blue">
                            <Thead bg="gray.50">
                                <Tr>
                                    <Th
                                        rowSpan="2"
                                        textTransform="none"
                                        fontSize={'sm'}
                                    >
                                        Gene
                                    </Th>
                                    <Th
                                        rowSpan="2"
                                        textTransform="none"
                                        fontSize={'sm'}
                                    >
                                        Chr. (GRCh38)
                                    </Th>
                                    <Th
                                        rowSpan="2"
                                        textTransform="none"
                                        fontSize={'sm'}
                                    >
                                        Position
                                    </Th>
                                    <Th
                                        colSpan="4"
                                        textTransform="none"
                                        fontSize={'sm'}
                                    >
                                        Coefficients for each sample
                                    </Th>
                                </Tr>
                                <Tr>
                                    <Th textTransform="none" fontSize={'sm'}>
                                        Blood
                                    </Th>
                                    <Th textTransform="none" fontSize={'sm'}>
                                        Saliva
                                    </Th>
                                    <Th textTransform="none" fontSize={'sm'}>
                                        Buccal Swab
                                    </Th>
                                    <Th textTransform="none" fontSize={'sm'}>
                                        All Types
                                    </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Tr>
                                    <Td colSpan="3" textAlign="center">
                                        (intercept)
                                    </Td>
                                    <Td textAlign="right">8.052</Td>
                                    <Td textAlign="right">7.527</Td>
                                    <Td textAlign="right">-9.277</Td>
                                    <Td textAlign="right">-3.447</Td>
                                </Tr>
                                <Tr>
                                    <Td>ELOVL2</Td>
                                    <Td textAlign="center">6</Td>
                                    <Td textAlign="right">11044628</Td>
                                    <Td textAlign="right">55.673</Td>
                                    <Td textAlign="right">47.078</Td>
                                    <Td textAlign="right">32.589</Td>
                                    <Td textAlign="right">44.59</Td>
                                </Tr>
                                <Tr>
                                    <Td>FHL2</Td>
                                    <Td textAlign="center">2</Td>
                                    <Td textAlign="right">105399282</Td>
                                    <Td textAlign="right">47.141</Td>
                                    <Td textAlign="right">42.389</Td>
                                    <Td textAlign="right">49.115</Td>
                                    <Td textAlign="right">50.188</Td>
                                </Tr>
                                <Tr>
                                    <Td>KLF14</Td>
                                    <Td textAlign="center">7</Td>
                                    <Td textAlign="right">130734355</Td>
                                    <Td textAlign="right">62.87</Td>
                                    <Td textAlign="right">48.479</Td>
                                    <Td textAlign="right">41.814</Td>
                                    <Td textAlign="right">49.747</Td>
                                </Tr>
                                <Tr>
                                    <Td>MIR29B2C</Td>
                                    <Td textAlign="center">1</Td>
                                    <Td textAlign="right">207823681</Td>
                                    <Td textAlign="right">-29.075</Td>
                                    <Td textAlign="right">-28.159</Td>
                                    <Td textAlign="right">-9.228</Td>
                                    <Td textAlign="right">-17.131</Td>
                                </Tr>
                                <Tr>
                                    <Td>TRIM59</Td>
                                    <Td textAlign="center">3</Td>
                                    <Td textAlign="right">160450189</Td>
                                    <Td textAlign="right">23.671</Td>
                                    <Td textAlign="right">41.281</Td>
                                    <Td textAlign="right">59.484</Td>
                                    <Td textAlign="right">43.22</Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </Box>
                </VStack>
            </Flex>
        </Box>
    );
};

export default PredSnapBsb;
