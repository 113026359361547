import React, { useState, useMemo } from 'react';
import {
    Box,
    Button,
    Heading,
    VStack,
    HStack,
    Input,
    Select,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Flex,
    InputGroup,
    InputRightElement,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
} from '@chakra-ui/react';
import { useTable, useSortBy, useGlobalFilter } from 'react-table';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';
import { MdOutlineChevronRight } from 'react-icons/md';
import { IoClose } from 'react-icons/io5';
import { debounce } from 'lodash';
import { DATA_SNAP_BSB } from './data';
const data = DATA_SNAP_BSB;

function MethylSnapBsb() {
    const [filter, setFilter] = useState('');
    const [bodyFluidType, setBodyFluidType] = useState('');

    const columns = useMemo(
        () => [
            { Header: 'Genetic Analyzer', accessor: 'g_analyzer' },
            { Header: 'Body Fluid Type', accessor: 'bf_type' },
            { Header: 'Age', accessor: 'age' },
            { Header: 'ELOVL2', accessor: 'evl2' },
            { Header: 'FHL2', accessor: 'f2' },
            { Header: 'KLF14', accessor: 'k14' },
            { Header: 'MIR29B2C', accessor: 'm29' },
            { Header: 'TRIM59', accessor: 't59' },
        ],
        []
    );

    const filteredData = useMemo(() => {
        let filtered = data;
        if (bodyFluidType) {
            filtered = filtered.filter((row) =>
                row.bf_type.includes(bodyFluidType)
            );
        }
        if (filter) {
            filtered = filtered.filter((row) =>
                Object.values(row).some((value) =>
                    value
                        .toString()
                        .toLowerCase()
                        .includes(filter.toLowerCase())
                )
            );
        }
        return filtered;
    }, [bodyFluidType, filter]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups = [],
        rows = [],
        prepareRow,
        setGlobalFilter,
        setAllFilters,
        setSortBy,
    } = useTable({ columns, data: filteredData }, useGlobalFilter, useSortBy);

    const resetSort = () => {
        setSortBy([]);
    };
    const handleSearchChange = debounce((value) => {
        setFilter(value);
        setGlobalFilter(value || undefined);
    }, 10);

    const clearSearch = () => {
        setFilter('');
        setGlobalFilter(undefined);
    };

    return (
        <Box py={6} px={2}>
            <Box>
                <VStack
                    spacing="12px"
                    align="left"
                    justifyContent="space-between"
                    mb={6}
                >
                    <VStack
                        spacing="7px"
                        align="left"
                        justifyContent="space-between"
                        mb={4}
                    >
                        <Breadcrumb
                            fontSize="s"
                            spacing="6px"
                            separator={
                                <MdOutlineChevronRight color="gray.500" />
                            }
                        >
                            <BreadcrumbItem isCurrentPage>
                                <BreadcrumbLink>SNapShot</BreadcrumbLink>
                            </BreadcrumbItem>

                            <BreadcrumbItem isCurrentPage>
                                <BreadcrumbLink>Age</BreadcrumbLink>
                            </BreadcrumbItem>

                            <BreadcrumbItem isCurrentPage>
                                <BreadcrumbLink>
                                    Blood, Saliva, Buccal Swab
                                </BreadcrumbLink>
                            </BreadcrumbItem>
                        </Breadcrumb>
                        <Heading as="h1" size="lg">
                            Blood, Saliva, and Buccal Swab
                        </Heading>
                    </VStack>
                    <HStack spacing="24px">
                        <Button
                            as="a"
                            variant="link"
                            href="/documents/MethylationDB-SNaPShot-BloodSalivaBuccalcells.csv"
                            download
                            color="#2b6cb0"
                        >
                            Download CSV
                        </Button>
                        <Button
                            as="a"
                            variant="link"
                            href="../documents/3_Age-prediction-using-DNA-methylation-for-blood-saliva-buccal-cells.pdf"
                            download
                            color="#2b6cb0"
                        >
                            Download Protocol (PDF)
                        </Button>
                    </HStack>
                </VStack>
                <Flex alignItems="center" gap="2">
                    <HStack mb={4} spacing={2}>
                        <Box>
                            <Select
                                placeholder="Select Body Fluid Type"
                                value={bodyFluidType}
                                onChange={(e) =>
                                    setBodyFluidType(e.target.value)
                                }
                            >
                                {Array.from(
                                    new Set(data.map((item) => item.bf_type))
                                ).map((type) => (
                                    <option key={type} value={type}>
                                        {type}
                                    </option>
                                ))}
                            </Select>
                        </Box>
                        <Box>
                            <InputGroup>
                                <Input
                                    placeholder="Search..."
                                    value={filter}
                                    onChange={(e) =>
                                        handleSearchChange(e.target.value)
                                    }
                                />
                                <InputRightElement>
                                    {filter && (
                                        <IoClose
                                            color="gray.500"
                                            cursor="pointer"
                                            onClick={clearSearch}
                                        />
                                    )}
                                </InputRightElement>
                            </InputGroup>
                        </Box>
                        <Box>
                            <Button onClick={resetSort}>Reset Sorter</Button>
                        </Box>
                    </HStack>
                </Flex>
            </Box>
            <Box
                maxHeight="900px"
                maxWidth="100%"
                overflowY="auto"
                border="1px solid #E1E8ED"
            >
                <Table {...getTableProps()}>
                    <Thead>
                        {headerGroups.map((headerGroup) => (
                            <Tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <Th
                                        fontSize="14px"
                                        {...column.getHeaderProps(
                                            column.getSortByToggleProps()
                                        )}
                                        style={{
                                            backgroundColor: '#2b6cb0',
                                            color: 'white',
                                            position: 'sticky',
                                            top: 0,
                                            zIndex: 1,
                                        }}
                                        textTransform="none"
                                        width="auto"
                                    >
                                        {column.render('Header')}
                                        <Flex>
                                            {column.isSorted ? (
                                                column.isSortedDesc ? (
                                                    <FaArrowDown />
                                                ) : (
                                                    <FaArrowUp />
                                                )
                                            ) : (
                                                ''
                                            )}
                                        </Flex>
                                    </Th>
                                ))}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody {...getTableBodyProps()}>
                        {rows.map((row) => {
                            prepareRow(row);
                            return (
                                <Tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                        <Td
                                            {...cell.getCellProps()}
                                            width="auto"
                                        >
                                            {cell.render('Cell')}
                                        </Td>
                                    ))}
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
            </Box>
        </Box>
    );
}

export default MethylSnapBsb;
