import React, { useState } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Text,
    Button,
    Box,
    Flex,
    useToast,
    NumberInput,
    NumberInputField,
    VStack,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Heading,
} from '@chakra-ui/react';
import { MdOutlineChevronRight } from 'react-icons/md';

const PredSnapMuscle = () => {
    const [inputs, setInputs] = useState({
        ma19b: '',
        ma19g: '',
        ma13b: '',
        ma13g: '',
        ma4b: '',
        ma4g: '',
        ma1b: '',
        ma1g: '',
        ma8b: '',
        ma8g: '',
        ma18b: '',
        ma18g: '',
        ma10b: '',
        ma10g: '',
        ma19_methyl: '',
        ma13_methyl: '',
        ma4_methyl: '',
        ma1_methyl: '',
        ma8_methyl: '',
        ma18_methyl: '',
        ma10_methyl: '',
    });

    const [results, setResults] = useState({
        ma19_result: null,
        ma13_result: null,
        ma4_result: null,
        ma1_result: null,
        ma8_result: null,
        ma18_result: null,
        ma10_result: null,

        snap_muscle_result: null,
        snap_muscle_result_methyl: null,
    });

    const [showResultsA, setShowResultsA] = useState(false);
    const [showResultsB, setShowResultsB] = useState(false);
    const [isCalculateBDisabled, setIsCalculateBDisabled] = useState(false);
    const [warningMessage1, setWarningMessage1] = useState(false);
    const [warningMessage2, setWarningMessage2] = useState(false);

    const toast = useToast();

    const handlePaste = (e) => {
        e.preventDefault();
        const paste = e.clipboardData
            .getData('text')
            .split(/\r\n|\n|\r/)
            .map((row) => row.split('\t').map((cell) => cell.trim()));
        const fields = [
            'ma19b',
            'ma19g',
            'ma13b',
            'ma13g',
            'ma4b',
            'ma4g',
            'ma1b',
            'ma1g',
            'ma8b',
            'ma8g',
            'ma18b',
            'ma18g',
            'ma10b',
            'ma10g',
        ];
        let updatedInputs = { ...inputs };
        paste.forEach((value, index) => {
            if (fields[index]) {
                updatedInputs[fields[index]] = value;
            }
        });
        setInputs(updatedInputs);
    };

    const handlePasteMethyl = (e) => {
        e.preventDefault();
        const paste = e.clipboardData
            .getData('text')
            .split(/\r\n|\n|\r/)
            .map((row) => row.split('\t').map((cell) => cell.trim()));
        const fields = [
            'ma19_methyl',
            'ma13_methyl',
            'ma4_methyl',
            'ma1_methyl',
            'ma8_methyl',
            'ma18_methyl',
            'ma10_methyl',
        ];
        let updatedInputs = { ...inputs };
        paste.forEach((value, index) => {
            if (fields[index]) {
                updatedInputs[fields[index]] = value;
            }
        });
        setInputs(updatedInputs);
    };

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setInputs((prev) => ({ ...prev, [id]: value }));
    };

    const handleReset = () => {
        setInputs({});
        setResults({});
        setShowResultsA(false);
        setShowResultsA(false);
    };

    const tablerow = [
        {
            name: 'MA_19',
            first: 'Blue',
            second: 'Green',
            var1: 'ma19b',
            var2: 'ma19g',
            result: results.ma19_result,
            input: 'ma19_methyl',
        },
        {
            name: 'MA_13',
            first: 'Blue',
            second: 'Green',
            var1: 'ma13b',
            var2: 'ma13g',
            result: results.ma13_result,
            input: 'ma13_methyl',
        },
        {
            name: 'MA_4',
            first: 'Blue',
            second: 'Green',
            var1: 'ma4b',
            var2: 'ma4g',
            result: results.ma4_result,
            input: 'ma4_methyl',
        },
        {
            name: 'MA_1',
            first: 'Blue',
            second: 'Green',
            var1: 'ma1b',
            var2: 'ma1g',
            result: results.ma1_result,
            input: 'ma1_methyl',
        },
        {
            name: 'MA_8',
            first: 'Blue',
            second: 'Green',
            var1: 'ma8b',
            var2: 'ma8g',
            result: results.ma8_result,
            input: 'ma8_methyl',
        },
        {
            name: 'MA_18',
            first: 'Blue',
            second: 'Green',
            var1: 'ma18b',
            var2: 'ma18g',
            result: results.ma18_result,
            input: 'ma18_methyl',
        },
        {
            name: 'MA_10',
            first: 'Blue',
            second: 'Green',
            var1: 'ma10b',
            var2: 'ma10g',
            result: results.ma10_result,
            input: 'ma10_methyl',
        },
    ];

    const handleCalculate = () => {
        const {
            ma19b,
            ma19g,
            ma13b,
            ma13g,
            ma4b,
            ma4g,
            ma1b,
            ma1g,
            ma8b,
            ma8g,
            ma18b,
            ma18g,
            ma10b,
            ma10g,
        } = inputs;

        const ma19_result =
            parseFloat(ma19b) / (parseFloat(ma19b) + parseFloat(ma19g));
        const ma13_result =
            parseFloat(ma13b) / (parseFloat(ma13b) + parseFloat(ma13g));
        const ma4_result =
            parseFloat(ma4b) / (parseFloat(ma4b) + parseFloat(ma4g));
        const ma1_result =
            parseFloat(ma1b) / (parseFloat(ma1b) + parseFloat(ma1g));
        const ma8_result =
            parseFloat(ma8b) / (parseFloat(ma8b) + parseFloat(ma8g));
        const ma18_result =
            parseFloat(ma18b) / (parseFloat(ma18b) + parseFloat(ma18g));
        const ma10_result =
            parseFloat(ma10b) / (parseFloat(ma10b) + parseFloat(ma10g));

        const snap_muscle_result =
            43.015522590906 +
            ma19_result * 70.35057904 +
            ma13_result * 19.387623 +
            ma4_result * -22.37044178 +
            ma1_result * 64.56629749 +
            ma8_result * -35.13383098 +
            ma18_result * -55.07274798 +
            ma10_result * 103.56204567;

        const resultA = {
            ma19_result: 'Value ma19',
            ma13_result: 'Value ma13',
            ma4_result: 'Value ma4',
            ma1_result: 'Value ma1',
            ma8_result: 'Value ma8',
            ma18_result: 'Value ma18',
            ma10_result: 'Value ma10',
            snap_muscle_result: 'Value maER',
        };

        setResults({
            ...resultA,
            ma19_result: ma19_result.toFixed(4),
            ma13_result: ma13_result.toFixed(4),
            ma4_result: ma4_result.toFixed(4),
            ma1_result: ma1_result.toFixed(4),
            ma8_result: ma8_result.toFixed(4),
            ma18_result: ma18_result.toFixed(4),
            ma10_result: ma10_result.toFixed(4),
            snap_muscle_result: snap_muscle_result.toFixed(4),
        });

        setShowResultsA(true);
        setShowResultsB(false);
        setIsCalculateBDisabled(true);
    };

    const handleCalculateMethyl = () => {
        const {
            ma19_methyl,
            ma13_methyl,
            ma4_methyl,
            ma1_methyl,
            ma8_methyl,
            ma18_methyl,
            ma10_methyl,
        } = inputs;

        const isEmpty = [
            ma19_methyl,
            ma13_methyl,
            ma4_methyl,
            ma1_methyl,
            ma8_methyl,
            ma18_methyl,
            ma10_methyl,
        ].some((val) => val === '');

        const resultB = {
            snap_muscle_result_methyl: 'Value ElasticNet',
        };

        if (isEmpty) {
            setWarningMessage2(true);
            setResults({
                ...resultB,
                snap_muscle_result_methyl: NaN,
            });
        } else {
            setWarningMessage2(false);

            const snap_muscle_result_methyl =
                43.015522590906 +
                ma19_methyl * 70.35057904 +
                ma13_methyl * 19.387623 +
                ma4_methyl * -22.37044178 +
                ma1_methyl * 64.56629749 +
                ma8_methyl * -35.13383098 +
                ma18_methyl * -55.07274798 +
                ma10_methyl * 103.56204567;

            setResults({
                ...results,
                snap_muscle_result_methyl: snap_muscle_result_methyl.toFixed(4),
            });
        }

        setShowResultsA(false);
        setShowResultsB(true);
    };

    return (
        <Box p={4}>
            {/* Header */}
            <VStack
                spacing="12px"
                align="left"
                justifyContent="space-between"
                mb={6}
            >
                <VStack
                    spacing="7px"
                    align="left"
                    justifyContent="space-between"
                    mb={4}
                >
                    <Breadcrumb
                        fontSize="s"
                        spacing="6px"
                        separator={<MdOutlineChevronRight color="gray.500" />}
                    >
                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink>SNapShot</BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink>Muscle</BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                    <Heading as="h1" size="lg">
                        Muscle
                    </Heading>
                </VStack>
            </VStack>
            {/* Calculator */}
            <VStack
                spacing="12px"
                align="left"
                justifyContent="space-between"
                mb={6}
            >
                {/* Inputs & Methyl Results */}
                <Flex
                    // backgroundColor="gray.20"
                    direction="row"
                    height="955px"
                    width="800px"
                >
                    <Box flex="6.5" onPaste={handlePaste}>
                        {/* Table Left */}
                        <Table colorScheme="blue" height="100%" size="sm">
                            <Thead>
                                <Tr borders="0">
                                    <Th>
                                        <Button
                                            colorScheme="gray"
                                            onClick={handleReset}
                                            m={2}
                                        >
                                            Reset All
                                        </Button>
                                    </Th>
                                    <Th></Th>
                                    <Th>
                                        <Box align="center">
                                            <Button
                                                colorScheme="blue"
                                                onClick={handleCalculate}
                                                m={2}
                                                w="70%"
                                            >
                                                Calculate
                                            </Button>
                                        </Box>
                                    </Th>
                                </Tr>
                                <Tr bg="gray.50">
                                    <Td></Td>
                                    <Td></Td>
                                    <Td textAlign="center">
                                        <Text fontSize="md">Input (rfu)</Text>
                                    </Td>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {tablerow.map((item) => (
                                    <React.Fragment key={item.name}>
                                        <Tr>
                                            <Td rowSpan="2">
                                                <Text
                                                    fontSize="md"
                                                    textAlign="center"
                                                >
                                                    {item.name}
                                                </Text>
                                            </Td>
                                            <Td>
                                                <Text
                                                    fontSize="md"
                                                    textAlign="center"
                                                >
                                                    {item.first}
                                                </Text>
                                            </Td>
                                            <Td>
                                                <NumberInput
                                                    // size="sm"
                                                    id={item.var1}
                                                    value={
                                                        inputs[item.var1] || ''
                                                    }
                                                    min={0}
                                                    onChange={(valueString) =>
                                                        handleInputChange({
                                                            target: {
                                                                id: item.var1,
                                                                value: valueString,
                                                            },
                                                        })
                                                    }
                                                    keepWithinRange={true}
                                                    m={0} // Remove all margins
                                                    mt="2px" // Top margin (adjust as needed)
                                                    mb="2px"
                                                >
                                                    <NumberInputField />
                                                </NumberInput>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td>
                                                <Text
                                                    fontSize="md"
                                                    textAlign="center"
                                                >
                                                    {item.second}
                                                </Text>
                                            </Td>
                                            <Td>
                                                <NumberInput
                                                    // size="sm"
                                                    id={item.var2}
                                                    value={
                                                        inputs[item.var2] || ''
                                                    }
                                                    min={0}
                                                    onChange={(valueString) =>
                                                        handleInputChange({
                                                            target: {
                                                                id: item.var2,
                                                                value: valueString,
                                                            },
                                                        })
                                                    }
                                                    keepWithinRange={true}
                                                    m={0} // Remove all margins
                                                    mt="2px" // Top margin (adjust as needed)
                                                    mb="2px"
                                                >
                                                    <NumberInputField />
                                                </NumberInput>
                                            </Td>
                                        </Tr>
                                    </React.Fragment>
                                ))}
                            </Tbody>
                        </Table>
                    </Box>
                    <Box flex="3.5" onPaste={handlePasteMethyl}>
                        {/* Table Right */}
                        <Table colorScheme="blue" height="100%" size="sm">
                            <Thead>
                                <Tr>
                                    <Th alignItems="center">
                                        <Box align="center">
                                            <Button
                                                colorScheme="blue"
                                                onClick={handleCalculateMethyl}
                                                disabled={isCalculateBDisabled}
                                                m={2}
                                                width="70%"
                                            >
                                                Calculate
                                            </Button>
                                        </Box>
                                    </Th>
                                </Tr>
                                <Tr bg="gray.50">
                                    <Td textAlign="center">
                                        <Text fontSize="md">
                                            Methylation (0-1)
                                        </Text>
                                    </Td>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {tablerow.map((item) => (
                                    <React.Fragment key={item.name}>
                                        <Tr>
                                            <Td rowSpan={2}>
                                                {showResultsA ? (
                                                    <Text
                                                        fontSize="md"
                                                        align="center"
                                                    >
                                                        {item.result}
                                                    </Text>
                                                ) : (
                                                    <NumberInput
                                                        width="100%"
                                                        id={item.input}
                                                        min={0}
                                                        max={1}
                                                        value={
                                                            inputs[
                                                                item.input
                                                            ] || ''
                                                        }
                                                        onChange={(
                                                            valueString
                                                        ) =>
                                                            handleInputChange({
                                                                target: {
                                                                    id: item.input,
                                                                    value: valueString,
                                                                },
                                                            })
                                                        }
                                                        m={0} // Remove all margins
                                                        mt={1} // Top margin (adjust as needed)
                                                        mb={1}
                                                    >
                                                        <NumberInputField />
                                                    </NumberInput>
                                                )}
                                            </Td>
                                        </Tr>
                                        <Tr></Tr>
                                    </React.Fragment>
                                ))}
                            </Tbody>
                        </Table>
                    </Box>
                </Flex>
                {/* Age Est. Results */}
                <Flex
                    direction="row"
                    // height="auto"
                    width="800px"
                    bg="gray.50"
                >
                    <Box flex="6.5">
                        {/* Table left */}
                        <Table colorScheme="blue" height="100%">
                            <Tbody>
                                <Tr>
                                    <Td colSpan="2">
                                        <Text whiteSpace="pre-line">
                                            Estimated age (years)
                                        </Text>
                                    </Td>
                                    <Td>ElasticNet regression</Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </Box>
                    <Box flex="3.5">
                        {/* Table right */}
                        <Table colorScheme="blue" height="100%">
                            <Tbody>
                                <Tr>
                                    <Td>
                                        {showResultsA
                                            ? results.snap_muscle_result
                                            : showResultsB
                                            ? results.snap_muscle_result_methyl
                                            : ''}
                                    </Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </Box>
                </Flex>
            </VStack>
        </Box>
    );
};

export default PredSnapMuscle;
