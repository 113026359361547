import React, { useState } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Text,
    Button,
    Box,
    Flex,
    useToast,
    NumberInput,
    NumberInputField,
    VStack,
    HStack,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Heading,
} from '@chakra-ui/react';
import { MdOutlineChevronRight } from 'react-icons/md';

const PredSnapSemen = () => {
    const [inputs, setInputs] = useState({
        ttcb: '',
        ttcg: '',
        cgb: '',
        cgg: '',
        noxb: '',
        noxg: '',
        ttc_methyl: '',
        cg_methyl: '',
        nox_methyl: '',
    });

    const [results, setResults] = useState({
        ttc_result: null, // methyl result
        cg_result: null,
        nox_result: null,
        semen_result01: null,
        semen_result02: null,
    });

    const [showResultsA, setShowResultsA] = useState(false);
    const [showResultsB, setShowResultsB] = useState(false);
    const [isCalculateBDisabled, setIsCalculateBDisabled] = useState(false);
    const [warningMessage1, setWarningMessage1] = useState(false);
    const [warningMessage2, setWarningMessage2] = useState(false);
    const toast = useToast();

    const handlePaste = (e) => {
        e.preventDefault();
        const paste = e.clipboardData
            .getData('text')
            .split(/\r\n|\n|\r/)
            .map((row) => row.split('\t').map((cell) => cell.trim()));
        const fields = ['ttcb', 'ttcg', 'cgb', 'cgg', 'noxb', 'noxg'];
        let updatedInputs = { ...inputs };
        paste.forEach((value, index) => {
            if (fields[index]) {
                updatedInputs[fields[index]] = value;
            }
        });
        setInputs(updatedInputs);
    };

    const handlePasteMethyl = (e) => {
        e.preventDefault();
        const paste = e.clipboardData
            .getData('text')
            .split(/\r\n|\n|\r/)
            .map((row) => row.split('\t').map((cell) => cell.trim()));
        const fields = ['ttc_methyl', 'cg_methyl', 'nox_methyl'];
        let updatedInputs = { ...inputs };
        paste.forEach((value, index) => {
            if (fields[index]) {
                updatedInputs[fields[index]] = value;
            }
        });
        setInputs(updatedInputs);
    };

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setInputs((prev) => ({ ...prev, [id]: value }));
    };

    const handleReset = () => {
        setInputs({});
        setResults({});
        setShowResultsA(false);
        setShowResultsA(false);
    };

    const tablerow = [
        {
            name: 'TTC7B',
            first: 'Blue',
            second: 'Green',
            var1: 'ttcb',
            var2: 'ttcg',
            result: results.ttc_result,
            input: 'ttc_methyl',
        },
        {
            name: 'cg12837463',
            first: 'Blue',
            second: 'Green',
            var1: 'cgb',
            var2: 'cgg',
            result: results.cg_result,
            input: 'cg_methyl',
        },
        {
            name: 'NOX4',
            first: 'Blue',
            second: 'Green',
            var1: 'noxb',
            var2: 'noxg',
            result: results.nox_result,
            input: 'nox_methyl',
        },
    ];

    const handleCalculate = () => {
        const { ttcb, ttcg, cgb, cgg, noxb, noxg } = inputs;

        const ttc_result =
            parseFloat(ttcb) / (parseFloat(ttcb) + parseFloat(ttcg));
        const cg_result = parseFloat(cgb) / (parseFloat(cgb) + parseFloat(cgg));
        const nox_result =
            parseFloat(noxb) / (parseFloat(noxb) + parseFloat(noxg));

        const semen_result01 =
            46.24 + -51.9 * ttc_result + -17.8 * cg_result + 54.1 * nox_result;

        const resultA = {
            ttc_result: 'Value ttc',
            cg_result: 'Value cg',
            nox_result: 'Value nox',

            semen_result01: 'Value 1',
        };

        setResults({
            ...resultA,
            ttc_result: ttc_result.toFixed(4),
            cg_result: cg_result.toFixed(4),
            nox_result: nox_result.toFixed(4),
            semen_result01: semen_result01.toFixed(4),
        });

        setShowResultsA(true);
        setShowResultsB(false);
        setIsCalculateBDisabled(true);
    };

    const handleCalculateMethyl = () => {
        const { ttc_methyl, cg_methyl, nox_methyl } = inputs;

        const isEmpty = [ttc_methyl, cg_methyl, nox_methyl].some(
            (val) => val === ''
        );

        const resultB = {
            semen_result02: 'Value 2',
        };

        if (isEmpty) {
            setWarningMessage2(true);
            setResults({
                ...resultB,
                semen_result02: NaN,
            });
        } else {
            setWarningMessage2(false);

            const semen_result02 =
                46.24 +
                -51.9 * ttc_methyl +
                -17.8 * cg_methyl +
                54.1 * nox_methyl;

            setResults({
                ...results,
                semen_result02: semen_result02.toFixed(4),
            });
        }

        setShowResultsA(false);
        setShowResultsB(true);
    };

    return (
        <Box p={4}>
            {/* Header */}
            <VStack
                spacing="12px"
                align="left"
                justifyContent="space-between"
                mb={6}
            >
                <VStack
                    spacing="7px"
                    align="left"
                    justifyContent="space-between"
                    mb={4}
                >
                    <Breadcrumb
                        fontSize="s"
                        spacing="6px"
                        separator={<MdOutlineChevronRight color="gray.500" />}
                    >
                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink>SNapShot</BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink>Semen</BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                    <Heading as="h1" size="lg">
                        Semen
                    </Heading>
                </VStack>
                <HStack>
                    <Button
                        as="a"
                        variant="link"
                        href="../documents/4_Age_prediction_using_DNA_methylation_for_semen.pdf"
                        download
                        color="#2b6cb0"
                        mb={4}
                    >
                        Download Protocol (PDF)
                    </Button>
                </HStack>
            </VStack>
            {/* Calculator */}
            <VStack
                spacing="12px"
                align="left"
                justifyContent="space-between"
                mb={6}
            >
                {/* Inputs & Methyl Results */}
                <Flex
                    // backgroundColor="gray.20"
                    direction="row"
                    height="500px"
                    width="800px"
                >
                    <Box flex="6.5" onPaste={handlePaste}>
                        {/* Table Left */}
                        <Table colorScheme="blue" height="100%" size="sm">
                            <Thead>
                                <Tr borders="0">
                                    <Th>
                                        <Button
                                            colorScheme="gray"
                                            onClick={handleReset}
                                            m={2}
                                        >
                                            Reset All
                                        </Button>
                                    </Th>
                                    <Th></Th>
                                    <Th>
                                        <Box align="center">
                                            <Button
                                                colorScheme="blue"
                                                onClick={handleCalculate}
                                                m={2}
                                                w="70%"
                                            >
                                                Calculate
                                            </Button>
                                        </Box>
                                    </Th>
                                </Tr>
                                <Tr bg="gray.50">
                                    <Td></Td>
                                    <Td></Td>
                                    <Td textAlign="center">
                                        <Text fontSize="md">Input (rfu)</Text>
                                    </Td>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {tablerow.map((item) => (
                                    <React.Fragment key={item.name}>
                                        <Tr>
                                            <Td rowSpan="2">
                                                <Text
                                                    fontSize="md"
                                                    textAlign="center"
                                                >
                                                    {item.name}
                                                </Text>
                                            </Td>
                                            <Td>
                                                <Text
                                                    fontSize="md"
                                                    textAlign="center"
                                                >
                                                    {item.first}
                                                </Text>
                                            </Td>
                                            <Td>
                                                <NumberInput
                                                    // size="sm"
                                                    id={item.var1}
                                                    value={
                                                        inputs[item.var1] || ''
                                                    }
                                                    min={0}
                                                    onChange={(valueString) =>
                                                        handleInputChange({
                                                            target: {
                                                                id: item.var1,
                                                                value: valueString,
                                                            },
                                                        })
                                                    }
                                                    keepWithinRange={true}
                                                    m={0} // Remove all margins
                                                    mt="2px" // Top margin (adjust as needed)
                                                    mb="2px"
                                                >
                                                    <NumberInputField />
                                                </NumberInput>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td>
                                                <Text
                                                    fontSize="md"
                                                    textAlign="center"
                                                >
                                                    {item.second}
                                                </Text>
                                            </Td>
                                            <Td>
                                                <NumberInput
                                                    // size="sm"
                                                    id={item.var2}
                                                    value={
                                                        inputs[item.var2] || ''
                                                    }
                                                    min={0}
                                                    onChange={(valueString) =>
                                                        handleInputChange({
                                                            target: {
                                                                id: item.var2,
                                                                value: valueString,
                                                            },
                                                        })
                                                    }
                                                    keepWithinRange={true}
                                                    m={0} // Remove all margins
                                                    mt="2px" // Top margin (adjust as needed)
                                                    mb="2px"
                                                >
                                                    <NumberInputField />
                                                </NumberInput>
                                            </Td>
                                        </Tr>
                                    </React.Fragment>
                                ))}
                            </Tbody>
                        </Table>
                    </Box>
                    <Box flex="3.5" onPaste={handlePasteMethyl}>
                        {/* Table Right */}
                        <Table colorScheme="blue" height="100%" size="sm">
                            <Thead>
                                <Tr>
                                    <Th alignItems="center">
                                        <Box align="center">
                                            <Button
                                                colorScheme="blue"
                                                onClick={handleCalculateMethyl}
                                                disabled={isCalculateBDisabled}
                                                m={2}
                                                width="70%"
                                            >
                                                Calculate
                                            </Button>
                                        </Box>
                                    </Th>
                                </Tr>
                                <Tr bg="gray.50">
                                    <Td textAlign="center">
                                        <Text fontSize="md">
                                            Methylation (0-1)
                                        </Text>
                                    </Td>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {tablerow.map((item) => (
                                    <React.Fragment key={item.name}>
                                        <Tr>
                                            <Td rowSpan={2}>
                                                {showResultsA ? (
                                                    <Text
                                                        fontSize="md"
                                                        align="center"
                                                    >
                                                        {item.result}
                                                    </Text>
                                                ) : (
                                                    <NumberInput
                                                        width="100%"
                                                        id={item.input}
                                                        min={0}
                                                        max={1}
                                                        value={
                                                            inputs[
                                                                item.input
                                                            ] || ''
                                                        }
                                                        onChange={(
                                                            valueString
                                                        ) =>
                                                            handleInputChange({
                                                                target: {
                                                                    id: item.input,
                                                                    value: valueString,
                                                                },
                                                            })
                                                        }
                                                        m={0} // Remove all margins
                                                        mt={1} // Top margin (adjust as needed)
                                                        mb={1}
                                                    >
                                                        <NumberInputField />
                                                    </NumberInput>
                                                )}
                                            </Td>
                                        </Tr>
                                        <Tr></Tr>
                                    </React.Fragment>
                                ))}
                            </Tbody>
                        </Table>
                    </Box>
                </Flex>
                {/* Age Est. Results */}
                <Flex
                    direction="row"
                    // height="auto"
                    width="800px"
                    bg="gray.50"
                >
                    <Box flex="6.5">
                        {/* Table left */}
                        <Table colorScheme="blue" height="100%">
                            <Tbody>
                                <Tr>
                                    <Td colSpan="2">
                                        <Text whiteSpace="pre-line">
                                            Estimated age (years)
                                        </Text>
                                    </Td>
                                    <Td>Semen</Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </Box>
                    <Box flex="3.5">
                        {/* Table right */}
                        <Table colorScheme="blue" height="100%">
                            <Tbody>
                                <Tr>
                                    <Td>
                                        {showResultsA
                                            ? results.semen_result01
                                            : showResultsB
                                            ? results.semen_result02
                                            : ''}
                                    </Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </Box>
                </Flex>
            </VStack>
            {/* CpG Info */}
            <Flex
                // backgroundColor="gray.20"
                direction="row"
                width="800px"
            >
                <VStack
                    spacing="12px"
                    align="left"
                    justifyContent="space-between"
                    // mb={6}
                    mt={6}
                    pt={6}
                >
                    <Box>
                        <Text fontSize="xl" fontWeight="bold" color="#2b6cb0">
                            CpG Marker Information
                        </Text>
                    </Box>
                    <Box>
                        <Table colorScheme="blue">
                            <Thead bg="gray.50">
                                <Tr>
                                    <Th textTransform="none" fontSize={'sm'}>
                                        Gene
                                    </Th>
                                    <Th textTransform="none" fontSize={'sm'}>
                                        Chr. (GRCh38)
                                    </Th>
                                    <Th textTransform="none" fontSize={'sm'}>
                                        Position
                                    </Th>
                                    <Th textTransform="none" fontSize={'sm'}>
                                        Coefficient
                                    </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Tr>
                                    <Td colSpan="3" textAlign="center">
                                        (intercept)
                                    </Td>
                                    <Td textAlign="right">46.24</Td>
                                </Tr>
                                <Tr>
                                    <Td>TTC7B</Td>
                                    <Td textAlign="center">14</Td>
                                    <Td textAlign="right">90817262</Td>
                                    <Td textAlign="right">-51.9</Td>
                                </Tr>
                                <Tr>
                                    <Td>cg12837463</Td>
                                    <Td textAlign="center">7</Td>
                                    <Td textAlign="right">35260617</Td>
                                    <Td textAlign="right">-17.8</Td>
                                </Tr>
                                <Tr>
                                    <Td>NOX4</Td>
                                    <Td textAlign="center">11</Td>
                                    <Td textAlign="right">89589683</Td>
                                    <Td textAlign="right">54.1</Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </Box>
                </VStack>
            </Flex>
        </Box>
    );
};

export default PredSnapSemen;
