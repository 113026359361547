import React, { useState } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Text,
    Button,
    Box,
    Flex,
    useToast,
    NumberInput,
    NumberInputField,
    VStack,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Heading,
} from '@chakra-ui/react';
import { MdOutlineChevronRight } from 'react-icons/md';

const PredSnapDermis = () => {
    const [inputs, setInputs] = useState({
        der01b: '',
        der01g: '',
        der02b: '',
        der02g: '',
        der03b: '',
        der03g: '',
        der04b: '',
        der04g: '',
        der05b: '',
        der05g: '',
        der06b: '',
        der06g: '',
        der07b: '',
        der07g: '',
        der08b: '',
        der08g: '',
        der09b: '',
        der09g: '',
        der01_methyl: '',
        der02_methyl: '',
        der03_methyl: '',
        der04_methyl: '',
        der05_methyl: '',
        der06_methyl: '',
        der07_methyl: '',
        der08_methyl: '',
        der09_methyl: '',
    });

    const [results, setResults] = useState({
        der01_result: null,
        der02_result: null,
        der03_result: null,
        der04_result: null,
        der05_result: null,
        der06_result: null,
        der07_result: null,
        der08_result: null,
        der09_result: null,

        snap_derLR_result: null,
        snap_derLR_result_methyl: null,
        snap_derSR_result: null,
        snap_derSR_result_methyl: null,
    });

    const [showResultsA, setShowResultsA] = useState(false);
    const [showResultsB, setShowResultsB] = useState(false);
    const [isCalculateBDisabled, setIsCalculateBDisabled] = useState(false);
    const [warningMessage1, setWarningMessage1] = useState(false);
    const [warningMessage2, setWarningMessage2] = useState(false);

    const toast = useToast();

    const handlePaste = (e) => {
        e.preventDefault();
        const paste = e.clipboardData
            .getData('text')
            .split(/\r\n|\n|\r/)
            .map((row) => row.split('\t').map((cell) => cell.trim()));
        const fields = [
            'der01b',
            'der01g',
            'der02b',
            'der02g',
            'der03b',
            'der03g',
            'der04b',
            'der04g',
            'der05b',
            'der05g',
            'der06b',
            'der06g',
            'der07b',
            'der07g',
            'der08b',
            'der08g',
            'der09b',
            'der09g',
        ];
        let updatedInputs = { ...inputs };
        paste.forEach((value, index) => {
            if (fields[index]) {
                updatedInputs[fields[index]] = value;
            }
        });
        setInputs(updatedInputs);
    };

    const handlePasteMethyl = (e) => {
        e.preventDefault();
        const paste = e.clipboardData
            .getData('text')
            .split(/\r\n|\n|\r/)
            .map((row) => row.split('\t').map((cell) => cell.trim()));
        const fields = [
            'der01_methyl',
            'der02_methyl',
            'der03_methyl',
            'der04_methyl',
            'der05_methyl',
            'der06_methyl',
            'der07_methyl',
            'der08_methyl',
            'der09_methyl',
        ];
        let updatedInputs = { ...inputs };
        paste.forEach((value, index) => {
            if (fields[index]) {
                updatedInputs[fields[index]] = value;
            }
        });
        setInputs(updatedInputs);
    };

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setInputs((prev) => ({ ...prev, [id]: value }));
    };

    const handleReset = () => {
        setInputs({});
        setResults({});
        setShowResultsA(false);
        setShowResultsA(false);
    };

    const tablerow = [
        {
            name: 'DER_AGE01',
            first: 'Blue',
            second: 'Green',
            var1: 'der01b',
            var2: 'der01g',
            result: results.der01_result,
            input: 'der01_methyl',
        },
        {
            name: 'DER_AGE02',
            first: 'Blue',
            second: 'Green',
            var1: 'der02b',
            var2: 'der02g',
            result: results.der02_result,
            input: 'der02_methyl',
        },
        {
            name: 'DER_AGE03',
            first: 'Blue',
            second: 'Green',
            var1: 'der03b',
            var2: 'der03g',
            result: results.der03_result,
            input: 'der03_methyl',
        },
        {
            name: 'DER_AGE04',
            first: 'Blue',
            second: 'Green',
            var1: 'der04b',
            var2: 'der04g',
            result: results.der04_result,
            input: 'der04_methyl',
        },
        {
            name: 'DER_AGE05',
            first: 'Blue',
            second: 'Green',
            var1: 'der05b',
            var2: 'der05g',
            result: results.der05_result,
            input: 'der05_methyl',
        },
        {
            name: 'DER_AGE06',
            first: 'Blue',
            second: 'Green',
            var1: 'der06b',
            var2: 'der06g',
            result: results.der06_result,
            input: 'der06_methyl',
        },
        {
            name: 'DER_AGE07',
            first: 'Blue',
            second: 'Green',
            var1: 'der07b',
            var2: 'der07g',
            result: results.der07_result,
            input: 'der07_methyl',
        },
        {
            name: 'DER_AGE08',
            first: 'Blue',
            second: 'Green',
            var1: 'der08b',
            var2: 'der08g',
            result: results.der08_result,
            input: 'der08_methyl',
        },
        {
            name: 'DER_AGE09',
            first: 'Blue',
            second: 'Green',
            var1: 'der09b',
            var2: 'der09g',
            result: results.der09_result,
            input: 'der09_methyl',
        },
    ];

    const handleCalculate = () => {
        const {
            der01b,
            der01g,
            der02b,
            der02g,
            der03b,
            der03g,
            der04b,
            der04g,
            der05b,
            der05g,
            der06b,
            der06g,
            der07b,
            der07g,
            der08b,
            der08g,
            der09b,
            der09g,
        } = inputs;

        const der01_result =
            parseFloat(der01b) / (parseFloat(der01b) + parseFloat(der01g));
        const der02_result =
            parseFloat(der02b) / (parseFloat(der02b) + parseFloat(der02g));
        const der03_result =
            parseFloat(der03b) / (parseFloat(der03b) + parseFloat(der03g));
        const der04_result =
            parseFloat(der04b) / (parseFloat(der04b) + parseFloat(der04g));
        const der05_result =
            parseFloat(der05b) / (parseFloat(der05b) + parseFloat(der05g));
        const der06_result =
            parseFloat(der06b) / (parseFloat(der06b) + parseFloat(der06g));
        const der07_result =
            parseFloat(der07b) / (parseFloat(der07b) + parseFloat(der07g));
        const der08_result =
            parseFloat(der08b) / (parseFloat(der08b) + parseFloat(der08g));
        const der09_result =
            parseFloat(der09b) / (parseFloat(der09b) + parseFloat(der09g));

        const snap_derLR_result =
            58.646 +
            -18.172 * der01_result +
            127.116 * der02_result +
            2.736 * der03_result +
            -97.933 * der04_result +
            124.782 * der05_result +
            -42.766 * der06_result +
            -42.397 * der07_result +
            -34.382 * der08_result +
            58.154 * der09_result;
        const snap_derSR_result =
            61.24 +
            126.2 * der02_result +
            -101.19 * der04_result +
            111.49 * der05_result +
            -49.03 * der06_result +
            -43.98 * der07_result +
            -33.58 * der08_result +
            57.92 * der09_result;

        const resultA = {
            der01_result: 'Value der01',
            der02_result: 'Value der02',
            der03_result: 'Value der03',
            der04_result: 'Value der04',
            der05_result: 'Value der05',
            der06_result: 'Value der06',
            der07_result: 'Value der07',
            der08_result: 'Value der08',
            der09_result: 'Value der09',
            snap_derLR_result: 'Value derLR',
            snap_derSR_result: 'Value derSR',
        };

        setResults({
            ...resultA,
            der01_result: der01_result.toFixed(4),
            der02_result: der02_result.toFixed(4),
            der03_result: der03_result.toFixed(4),
            der04_result: der04_result.toFixed(4),
            der05_result: der05_result.toFixed(4),
            der06_result: der06_result.toFixed(4),
            der07_result: der07_result.toFixed(4),
            der08_result: der08_result.toFixed(4),
            der09_result: der09_result.toFixed(4),
            snap_derLR_result: snap_derLR_result.toFixed(4),
            snap_derSR_result: snap_derSR_result.toFixed(4),
        });

        setShowResultsA(true);
        setShowResultsB(false);
        setIsCalculateBDisabled(true);
    };

    const handleCalculateMethyl = () => {
        const {
            der01_methyl,
            der02_methyl,
            der03_methyl,
            der04_methyl,
            der05_methyl,
            der06_methyl,
            der07_methyl,
            der08_methyl,
            der09_methyl,
        } = inputs;

        const isEmpty = [
            der01_methyl,
            der02_methyl,
            der03_methyl,
            der04_methyl,
            der05_methyl,
            der06_methyl,
            der07_methyl,
            der08_methyl,
            der09_methyl,
        ].some((val) => val === '');

        const resultB = {
            snap_derLR_result_methyl: 'Value Lasso',
            snap_derSR_result_methyl: 'Value Stepwise',
        };

        if (isEmpty) {
            setWarningMessage2(true);
            setResults({
                ...resultB,
                snap_derLR_result_methyl: NaN,
                snap_derSR_result_methyl: NaN,
            });
        } else {
            setWarningMessage2(false);

            const snap_derLR_result_methyl =
                58.646 +
                -18.172 * der01_methyl +
                127.116 * der02_methyl +
                2.736 * der03_methyl +
                -97.933 * der04_methyl +
                124.782 * der05_methyl +
                -42.766 * der06_methyl +
                -42.397 * der07_methyl +
                -34.382 * der08_methyl +
                58.154 * der09_methyl;
            const snap_derSR_result_methyl =
                61.24 +
                126.2 * der02_methyl +
                -101.19 * der04_methyl +
                111.49 * der05_methyl +
                -49.03 * der06_methyl +
                -43.98 * der07_methyl +
                -33.58 * der08_methyl +
                57.92 * der09_methyl;

            setResults({
                ...results,
                snap_derLR_result_methyl: snap_derLR_result_methyl.toFixed(4),
                snap_derSR_result_methyl: snap_derSR_result_methyl.toFixed(4),
            });
        }

        setShowResultsA(false);
        setShowResultsB(true);
    };

    return (
        <Box p={4}>
            {/* Header */}
            <VStack
                spacing="12px"
                align="left"
                justifyContent="space-between"
                mb={6}
            >
                <VStack
                    spacing="7px"
                    align="left"
                    justifyContent="space-between"
                    mb={4}
                >
                    <Breadcrumb
                        fontSize="s"
                        spacing="6px"
                        separator={<MdOutlineChevronRight color="gray.500" />}
                    >
                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink>SNapShot</BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink>Dermis</BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                    <Heading as="h1" size="lg">
                        Dermis
                    </Heading>
                </VStack>
            </VStack>
            {/* Calculator */}
            <VStack
                spacing="12px"
                align="left"
                justifyContent="space-between"
                mb={6}
            >
                {/* Inputs & Methyl Results */}
                <Flex
                    // backgroundColor="gray.20"
                    direction="row"
                    height="1200px"
                    width="800px"
                >
                    <Box flex="6.5" onPaste={handlePaste}>
                        {/* Table Left */}
                        <Table colorScheme="blue" height="100%" size="sm">
                            <Thead>
                                <Tr borders="0">
                                    <Th>
                                        <Button
                                            colorScheme="gray"
                                            onClick={handleReset}
                                            m={2}
                                        >
                                            Reset All
                                        </Button>
                                    </Th>
                                    <Th></Th>
                                    <Th>
                                        <Box align="center">
                                            <Button
                                                colorScheme="blue"
                                                onClick={handleCalculate}
                                                m={2}
                                                w="70%"
                                            >
                                                Calculate
                                            </Button>
                                        </Box>
                                    </Th>
                                </Tr>
                                <Tr bg="gray.50">
                                    <Td></Td>
                                    <Td></Td>
                                    <Td textAlign="center">
                                        <Text fontSize="md">Input (rfu)</Text>
                                    </Td>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {tablerow.map((item) => (
                                    <React.Fragment key={item.name}>
                                        <Tr>
                                            <Td rowSpan="2">
                                                <Text
                                                    fontSize="md"
                                                    textAlign="center"
                                                >
                                                    {item.name}
                                                </Text>
                                            </Td>
                                            <Td>
                                                <Text
                                                    fontSize="md"
                                                    textAlign="center"
                                                >
                                                    {item.first}
                                                </Text>
                                            </Td>
                                            <Td>
                                                <NumberInput
                                                    // size="sm"
                                                    id={item.var1}
                                                    value={
                                                        inputs[item.var1] || ''
                                                    }
                                                    min={0}
                                                    onChange={(valueString) =>
                                                        handleInputChange({
                                                            target: {
                                                                id: item.var1,
                                                                value: valueString,
                                                            },
                                                        })
                                                    }
                                                    keepWithinRange={true}
                                                    m={0} // Remove all margins
                                                    mt="2px" // Top margin (adjust as needed)
                                                    mb="2px"
                                                >
                                                    <NumberInputField />
                                                </NumberInput>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td>
                                                <Text
                                                    fontSize="md"
                                                    textAlign="center"
                                                >
                                                    {item.second}
                                                </Text>
                                            </Td>
                                            <Td>
                                                <NumberInput
                                                    // size="sm"
                                                    id={item.var2}
                                                    value={
                                                        inputs[item.var2] || ''
                                                    }
                                                    min={0}
                                                    onChange={(valueString) =>
                                                        handleInputChange({
                                                            target: {
                                                                id: item.var2,
                                                                value: valueString,
                                                            },
                                                        })
                                                    }
                                                    keepWithinRange={true}
                                                    m={0} // Remove all margins
                                                    mt="2px" // Top margin (adjust as needed)
                                                    mb="2px"
                                                >
                                                    <NumberInputField />
                                                </NumberInput>
                                            </Td>
                                        </Tr>
                                    </React.Fragment>
                                ))}
                            </Tbody>
                        </Table>
                    </Box>
                    <Box flex="3.5" onPaste={handlePasteMethyl}>
                        {/* Table Right */}
                        <Table colorScheme="blue" height="100%" size="sm">
                            <Thead>
                                <Tr>
                                    <Th alignItems="center">
                                        <Box align="center">
                                            <Button
                                                colorScheme="blue"
                                                onClick={handleCalculateMethyl}
                                                disabled={isCalculateBDisabled}
                                                m={2}
                                                width="70%"
                                            >
                                                Calculate
                                            </Button>
                                        </Box>
                                    </Th>
                                </Tr>
                                <Tr bg="gray.50">
                                    <Td textAlign="center">
                                        <Text fontSize="md">
                                            Methylation (0-1)
                                        </Text>
                                    </Td>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {tablerow.map((item) => (
                                    <React.Fragment key={item.name}>
                                        <Tr>
                                            <Td rowSpan={2}>
                                                {showResultsA ? (
                                                    <Text
                                                        fontSize="md"
                                                        align="center"
                                                    >
                                                        {item.result}
                                                    </Text>
                                                ) : (
                                                    <NumberInput
                                                        width="100%"
                                                        id={item.input}
                                                        min={0}
                                                        max={1}
                                                        value={
                                                            inputs[
                                                                item.input
                                                            ] || ''
                                                        }
                                                        onChange={(
                                                            valueString
                                                        ) =>
                                                            handleInputChange({
                                                                target: {
                                                                    id: item.input,
                                                                    value: valueString,
                                                                },
                                                            })
                                                        }
                                                        m={0} // Remove all margins
                                                        mt={1} // Top margin (adjust as needed)
                                                        mb={1}
                                                    >
                                                        <NumberInputField />
                                                    </NumberInput>
                                                )}
                                            </Td>
                                        </Tr>
                                        <Tr></Tr>
                                    </React.Fragment>
                                ))}
                            </Tbody>
                        </Table>
                    </Box>
                </Flex>
                {/* Age Est. Results */}
                <Flex
                    direction="row"
                    // height="auto"
                    width="800px"
                    bg="gray.50"
                >
                    <Box flex="6.5">
                        {/* Table left */}
                        <Table colorScheme="blue" height="100%">
                            <Tbody>
                                <Tr>
                                    <Td colSpan="2" rowSpan="2">
                                        <Text whiteSpace="pre-line">
                                            Estimated age (years)
                                        </Text>
                                    </Td>
                                    <Td>Linear regression</Td>
                                </Tr>
                                <Tr>
                                    <Td>Stepwise regression</Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </Box>
                    <Box flex="3.5">
                        {/* Table right */}
                        <Table colorScheme="blue" height="100%">
                            <Tbody>
                                <Tr>
                                    <Td>
                                        {showResultsA
                                            ? results.snap_derLR_result
                                            : showResultsB
                                            ? results.snap_derLR_result_methyl
                                            : ''}
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>
                                        {showResultsA
                                            ? results.snap_derSR_result
                                            : showResultsB
                                            ? results.snap_derSR_result_methyl
                                            : ''}
                                    </Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </Box>
                </Flex>
            </VStack>
        </Box>
    );
};

export default PredSnapDermis;
